








import { Component, Vue } from 'vue-property-decorator'
import LogoService from '@/services/LogoService'

@Component
export default class Logo extends Vue {
  public image: any = ''

  async created() {
    await LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })
  }
}
