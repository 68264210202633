






















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import Footer from '@/components/Footer.vue'
import Logo from '@/components/Logo.vue'
import MemberRegisterServeice from '@/services/MemberService'
import MembershipInformationService from '@/services/MembershipInformationService'
import LayoutCompactFooterOnly from '@/layouts/LayoutCompactFooterOnly.vue'
import { Member } from '@/models/Member'
import CheckDevice from '../helpers/CheckDevice'
import moment from 'moment'
// @ts-ignore
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'

const dict = {
  custom: {
    firstName: {
      required: () => '「姓」を入力してください。',
      regex: () => '数字記号以外で入力してください。'
    },
    lastName: {
      required: () => '「名」を入力してください。',
      regex: () => '数字記号以外で入力してください。'
    },
    firstNameKana: {
      required: () => '「せい」を入力してください。',
      regex: () => '「せい」を全てひらがなで入力してください。'
    },
    lastNameKana: {
      required: () => '「めい」を入力してください。',
      regex: () => '「めい」を全てひらがなで入力してください。'
    },
    email: {
      required: () => '「メールアドレス」を入力してください。',
      email: () => '「メールアドレス」を正しく入力してください。'
    },
    emailConfirmation: {
      required: () => 'メールアドレスを入力する必要があります。',
      confirmed: () => '入力内容を確認してください。'
    },
    password: {
      required: () => '「パスワード」を入力してください。',
      min: () =>
        '「パスワード」を大文字を含む半角英数字9文字以上20文字以下で入力してください。',
      regex: () => 'パスワードに無効な文字が含まれている。',
      is_not: () => 'パスワードが無効です。'
    },
    passwordConfirmation: {
      required: () => '「パスワード」を入力してください。',
      confirmed: () =>
        '「パスワード」を大文字を含む半角英数字9文字以上20文字以下で入力してください。'
    },
    phoneNo: {
      required: () => '携帯電話番号を入力してください。',
      regex: () => '電話番号は半角数字でなければなりません。',
      min: () => '「電話番号」を半角英数10~11桁で入力してください。',
      max: () => '「電話番号」を半角英数10~11桁で入力してください。'
    },
    birthday: {
      required: () => '「生年月日」を指定してください。'
    },
    city: {
      required: () => '「市区町村」を入力してください。'
    },
    prefectures: {
      required: () => '「都道府県」を入力してください。'
    },
    address: {
      required: () => '「以降の住所」を入力してください。'
    },
    zipCode: {
      required: () => '「郵便番号」を半角数字7桁で入力してください。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    Footer,
    ValidationProvider,
    ValidationObserver,
    Logo,
    DatePicker,
    LayoutCompactFooterOnly
  }
})
export default class MemberRegister extends Vue {
  public pattern: string = `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9|@#$!%*?,./])(?!.*[¥&<>"'(){}[]-=^_:;+|])[a-zA-Z\\d@#$!%*?,./]+$`
  public patternNumber: string = `^(\\d)+$`
  public now: Date = new Date()
  public isErrorLastName: boolean = false
  public isErrorFirstName: boolean = false
  public isErrorLastNameKana: boolean = false
  public isErrorFirstNameKana: boolean = false
  public isErrorEmail: boolean = false
  public isErrorEmailConfirmation: boolean = false
  public isErrorPassword: boolean = false
  public isErrorPasswordConfirmation: boolean = false
  public isErrorPhoneNo: boolean = false
  public isErrorBirthday: boolean = false
  public errorAll: boolean = true
  public member = new Member()
  public isMobile: boolean = false
  public lang: any = ''

  private store_id?: any = ''

  created() {
    if (CheckDevice.isMobile()) {
      this.isMobile = true
    }
    if (localStorage.getItem('store_id')) {
      this.store_id = localStorage.getItem('store_id')
      if (localStorage.getItem('memberRegister')) {
        this.member = localStorage.getItem('memberRegister')
          ? JSON.parse(localStorage.getItem('memberRegister') || '')
          : []
      }
    } else {
      this.$router.push('select-store')
    }
  }

  mounted() {
    let birthDay: any = this.$refs.birthDay
    birthDay.max = moment().format('YYYY-MM-DD')
  }

  public register() {
    this.errorAll = true
  }

  public handleRegisterMember() {
    MemberRegisterServeice.registerMember(this.member, this.store_id).then(
      (response: any) => {
        if (response.status) {
          if (localStorage.getItem('memberRegister')) {
            localStorage.removeItem('memberRegister')
          }
          this.$router.push({
            name: 'member-register-status',
            params: { status: 'success' }
          })
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 440) {
          if (
            error.response.data.message[0].email[0] === 'EMAIL_ALREADY_EXISTED'
          ) {
            this.$router.push({
              name: 'member-register-status',
              params: { status: 'errorEmail' }
            })
          } else {
            this.$router.push({
              name: 'member-register-status',
              params: { status: 'errorSystem' }
            })
          }
        } else {
          this.$router.push({
            name: 'member-register-status',
            params: { status: 'errorSystem' }
          })
        }
      }
    )
  }

  public addressReflected() {
    MembershipInformationService.addressReflected(this.member.zip_code).then(
      (response: any) => {
        if (response.data) {
          let items = response.data.items
          this.member.city = items.city
          this.member.address = items.address
          this.member.prefecture = items.prefecture
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status) {
          this.member.city = ''
          this.member.address = ''
          this.member.prefecture = ''
        }
      }
    )
  }

  public goToTermsOfService() {
    localStorage.setItem('memberRegister', JSON.stringify(this.member))
    let routeData = this.$router.resolve({
      name: 'terms-of-service'
    })
    window.open(routeData.href, '_blank')
  }

  public goToPrivacyPolicy() {
    localStorage.setItem('memberRegister', JSON.stringify(this.member))
    let routeData = this.$router.resolve({
      name: 'privacy-policy'
    })
    window.open(routeData.href, '_blank')
  }

  public handleBackLogin() {
    this.$router.push({ name: 'login' })
  }

  focusOutLastName() {
    this.isErrorLastName = true
  }

  focusInLastName() {
    this.isErrorLastName = false
    this.errorAll = false
  }

  focusOutFirstName() {
    this.isErrorFirstName = true
  }

  focusInFirstName() {
    this.isErrorFirstName = false
    this.errorAll = false
  }

  focusOutLastNameKana() {
    this.isErrorLastNameKana = true
  }

  focusInLastNameKana() {
    this.isErrorLastNameKana = false
    this.errorAll = false
  }

  focusOutFirstNameKana() {
    this.isErrorFirstNameKana = true
  }

  focusInFirstNameKana() {
    this.isErrorFirstNameKana = false
    this.errorAll = false
  }

  focusOutEmail() {
    this.isErrorEmail = true
  }

  focusInEmail() {
    this.isErrorEmail = false
    this.errorAll = false
  }

  focusOutEmailConfirmation() {
    this.isErrorEmailConfirmation = true
  }

  focusInEmailConfirmation() {
    this.isErrorEmailConfirmation = false
    this.errorAll = false
  }

  focusOutPassword() {
    this.isErrorPassword = true
  }

  focusInPassword() {
    this.isErrorPassword = false
    this.errorAll = false
  }

  focusOutPasswordConfirmation() {
    this.isErrorPasswordConfirmation = true
  }

  focusInPasswordConfirmation() {
    this.isErrorPasswordConfirmation = false
    this.errorAll = false
  }

  focusOutPhoneNo() {
    this.isErrorPhoneNo = true
  }

  focusInPhoneNo() {
    this.isErrorPhoneNo = false
    this.errorAll = false
  }

  focusOutBirthday() {
    this.isErrorBirthday = true
  }

  focusInBirthday() {
    this.isErrorBirthday = false
    this.errorAll = false
  }

  notAfterToday(date: any) {
    return date > new Date(new Date())
  }
}
