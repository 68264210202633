import Const from '../constants'
import { Member } from '../models/Member'
import axiosApiInstance from '../interceptors'

class MemberRegisterServeice {
  registerMember(data: Member, store_id: string) {
    let profile: any = {
      first_name: data.first_name,
      last_name: data.last_name,
      first_name_kana: data.first_name_kana,
      last_name_kana: data.last_name_kana,
      phone_no: data.phone_no,
      zip_code: data.zip_code,
      prefecture: data.prefecture,
      city: data.city,
      address: data.address,
      apartment_no: data.apartment_no,
      delivery_time_zone: data.delivery_time_zone,
    }
    if (data.birthday && data.birthday !== 'Invalid date') {
      profile.birthday = data.birthday
    }
    if (data.gender) {
      profile.gender = data.gender
    }
    let site_url = window.location.origin
    return axiosApiInstance.post(Const.api_url + 'member', {
      profile: profile,
      regist_url: site_url + '/member/active',
      email: data.email,
      email_confirmation: data.email_confirmation,
      password: data.password,
      password_confirmation: data.password_confirmation,
      phone_no: data.phone_no,
      store_id: store_id,
    })
  }

  registerMemberSuccess(id: any) {
    let site_url = window.location.origin + '/login'
    return axiosApiInstance.post(Const.api_url + 'member/active/' + Number(id), {
      login_url: site_url
    })
  }
}

export default new MemberRegisterServeice()